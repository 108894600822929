<template>
  <div id="sidebarmenu">
    <div v-for="(item, index) in items" :key="index">
      <div v-if="item.submenu">
        <div v-if="
          activePage.slug === item.slug ||
            activePage.slug === item.submenu[0].slug ||
            activePage.slug === item.submenu[1].slug
        ">
          <ItemDropdown :Name="item.name" :Url="item.url" :Slug="item.slug" :Submenu="item.submenu" :isActive="true" />
        </div>
        <div v-else>
          <ItemDropdown :Name="item.name" :Url="item.url" :Slug="item.slug" :Submenu="item.submenu" :isActive="false" />
        </div>
      </div>
      <div v-else>
        <div v-if="activePage.slug === item.slug">
          <ItemLink :Name="item.name" :Slug="item.slug" :Url="item.url" :isActive="true" />
        </div>
        <div v-else>
          <ItemLink :Name="item.name" :Slug="item.slug" :Url="item.url" :isActive="false" />
        </div>
      </div>
    </div>
    <div class="text-lg w-full mb-20 cus-button pointer text-primary" @click="openModalLogout()">
      Logout
    </div>

    <!-- Delete Campaign Modal -->
    <MiniblankModal v-if="showLogout" @close="closeModalLogout">

      <template v-slot:body>
        <div class="flex flex-col gap-4 p-4">
          <p class="font-semibold text-center">Are you sure that you want to Logout?</p>
          <div class="flex justify-around">
            <button @click="closeModalLogout" style="background-color: rgb(235, 235, 235);"
              class="border-button border-button-pri">Cancel</button>
            <button @click="LogoutAdmin"
              class="fill-button fill-button-pri">Logout</button>
          </div>
        </div>

      </template>

    </MiniblankModal>
  </div>
</template>

<script>
import items from "./sidebar_item";
import ItemDropdown from "./item_dropdown.vue";
import ItemLink from "./item_link.vue";
import MiniblankModal from "../../../components/miniblankModal.vue";

import { mapGetters } from "vuex";

export default {
computed: {
...mapGetters({
activePage: "general/activePage"
})
},

components: {
ItemLink,
ItemDropdown,
MiniblankModal
},
data() {
return {
items: items,
showLogout: false
};
},

methods: {
openModalLogout() {
this.showLogout = true
},
closeModalLogout() {
this.showLogout = false
},
LogoutAdmin() {
this.$store.dispatch("general/logout");
}
}
};
</script>

<style lang="scss" scoped>
#sidebarmenu {
  margin-top: 20px;
  margin-bottom: 10rem;
}

.cus-button {
  display: flex;
  justify-content: start;
  padding: 15px 20px;
  text-decoration: none;

  &:hover {
    transition: 100ms;
    padding-left: 30px;
  }
}
</style>
