<template>
  <div class="Sidebar">
    <Profile class="mt-6" />
    <SidebarItem class="Sidebargroup" />
    <!-- <VNavMenuGroup /> -->
  </div>
</template>

<script>
import Profile from "./Profile.vue";
import SidebarItem from "./sidebarItem/sidebar_item.vue";
import VNavMenuGroup from "../../layouts/components/vertical-nav-menu/VerticalNavMenuGroup.vue";
import VNavMenuItem from "../../layouts/components/vertical-nav-menu/VerticalNavMenuItem.vue";

export default {
  components: {
    Profile,
    SidebarItem,
    VNavMenuGroup,
    VNavMenuItem
  },
  data() {
    return {};
  }
};
</script>

<style lang="scss" scoped>
.HeaderLogo {
  justify-content: center;
  color: rgba(29, 56, 133, 0.5);
}
.Sidebar {
  background: #92A3B7;
  font-family: 'Montserrat', 'Athiti', sans-serif;
  padding-bottom: 5rem;
}
.logoGenAdmin {
  padding-right: 18%;
  padding-left: 5%;
  width: 100%;
}
.profilePic {
  width: 20vh;
  height: 20vh;
  margin-top: 2vh;
}
.Sidebargroup {
  // height: 70vh;
  overflow: scroll;
}
.line-blue {
  border-top: 3px solid #7595cc;
}
.square-blue {
  width: 12px;
  height: 12px;
  transform: rotate(45deg);
  background-color: #7595cc;
}
</style>
