<template>
  <div>
    <div
      class="text-lg cus-dropdown pointer"
      :class="{ active: isActive }"
      @click="ExpandDropdown()"
    >
      {{ Name }}
      <svg
        id="arrowhead"
        :class="{ active: isActive }"
        width="11"
        height="17"
        viewBox="0 0 11 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.5 15.535L9.5 8.55396"
          stroke="#4D4D4D"
          stroke-width="2"
          stroke-linecap="round"
        />
        <path
          d="M1.57898 1.5L9.49998 8.535"
          stroke="#4D4D4D"
          stroke-width="2"
          stroke-linecap="round"
        />
      </svg>
    </div>
    <div class="animate-dropdown" v-if="isActive === true">
      <div v-for="(menu, index) in Submenu" :key="index">
        <router-link
          v-if="activePage.slug === menu.slug"
          :class="{ active: isActive }"
          :to="menu.url"
          class="text-lg w-full cus-button"
          @click.native="ActivePage(menu.name, menu.slug, menu.url)"
        >
          {{ menu.name }}
        </router-link>
        <router-link
          v-else
          :to="menu.url"
          class="text-lg w-full cus-button"
          @click.native="ActivePage(menu.name, menu.slug, menu.url)"
        >
          {{ menu.name }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  methods: {
    ExpandDropdown() {
      this.isShow = !this.isShow;
      this.isActive = !this.isActive;
    },
    async ActivePage(name, slug, url) {
      this.$store.dispatch("general/push_activePage", {
        name: name,
        slug: slug,
        url: url
      });
    }
  },
  data() {
    return {
      menu: false,
      isShow: false
    };
  },
  props: {
    Name: String,
    Url: String,
    Submenu: Array,
    Slug: String,
    isActive: Boolean
  },
  computed: {
    ...mapGetters({
      activePage: "general/activePage"
    })
  }
};
</script>

<style lang="scss" scoped>
.cus-dropdown {
  display: flex;
  justify-content: space-between;
  padding: 15px;
  text-decoration: none;
  color: #4d4d4d;
  &:hover {
    transition: 100ms;
    padding-left: 30px;
  }
}
.animate-dropdown {
  animation: droping;
  animation-duration: 300ms;
}

.cus-button {
  display: flex;
  justify-content: start;
  padding: 15px 20px;
  background-color: #cdcdcd;
  text-decoration: none;

  &:hover {
    transition: 100ms;
    padding-left: 30px;
  }
}

.active svg {
  transition: 200ms;
  transform: rotate(90deg);
}

.active path {
  stroke: white;
}

.active {
  background-color: #6A7E91;
  color: white;
  animation-name: toActive;
  animation-duration: 500ms;
}

#no-btn {
  background: transparent;
}

@keyframes toActive {
  0% {
    background-color: #f0f3f4;
    color: #4d4d4d;
  }
  100% {
    background-color: #6A7E91;
    color: white;
  }
}

@keyframes droping {
  0% {
    margin-top: -30%;
    opacity: 0;
  }
  70% {
    opacity: 0.5;
  }
  100% {
    margin-top: 0%;
    opacity: 1;
  }
}
</style>
