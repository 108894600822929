<template>
  <div class="flex flex-col h-screen w-screen">
    <SideNavbar
      class="overflow-hidden Slidesidebar"
      v-if="window.width <= 768 && ShowSlidesidebar"
      v-click-outside="closeSlideSidebar"
    />
    <div class="h-full w-full flex main-template">
      <SideNavbar class="h-full sidebar-style" v-show="isShowsidebar" />
      <div class="flex flex-col h-full w-full overflow-hidden">
        <div class="flex grow-0 items-center" style="background-color: #6a7e91">
          <img
            class="ml-5 pointer"
            @click="goBack()"
            v-if="$route.name !== 'campaign' && $route.name != 'catalog'"
            src="@/assets/images/icon/back.svg"
            alt=""
          />
          <img
            class="ml-5 pointer"
            @click="ToggleSidebar()"
            src="@/assets/images/elements/hammer.svg"
            v-show="!Showsidebar"
          />
          <Header class="w-full" />
        </div>
        <div class="layout-style grow overflow-y-auto">
          <transition name="fade" mode="out-in">
            <router-view></router-view>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/views/elements/Header.vue";
import SideNavbar from "@/views/elements/SideNavbar.vue";
import themeConfig from "../../themeConfig";

export default {
  components: {
    Header,
    SideNavbar,
  },
  data() {
    return {
      routerTransition: themeConfig.routerTransition || "none",
      window: {
        width: 0,
        height: 0,
      },
      Showsidebar: false,
      ShowSlidesidebar: false,
    };
  },
  methods: {
    ToggleSidebar() {
      this.ShowSlidesidebar = true;
    },
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
    closeSlideSidebar() {
      this.ShowSlidesidebar = false;
    },
    goBack() {
      this.$router.back();
    },
  },

  mounted() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  computed: {
    isShowsidebar() {
      return this.window.width <= 768
        ? (this.Showsidebar = false)
        : (this.Showsidebar = true);
    },
    isShowSlidesidebar() {
      return this.window.width <= 768
        ? (this.ShowSildesidebar = false)
        : (this.ShowSildesidebar = true);
    },
  },
};
</script>

<style lang="scss" scoped>
.main-template {
  background: linear-gradient(180deg, #92a3b7 0%, #c2d7e5 46.35%, #6a7e91 100%);
  background-size: cover;
}

.Slidesidebar {
  position: absolute;
  width: 40% !important;
  z-index: 999;
  background-color: #92a3b7;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 8px 24px,
    rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px;
}
.sidebar-style {
  width: 25vw;
  z-index: 123;
}
.layout-style {
  height: 100%;
}
</style>
