<template>
  <div class="flex flex-row items-center  p-5 Header">
    <!-- <img class="flex-none mr-1" src="@/assets/images/logo/logo_FAstation.svg" /> -->
    <!-- <img class="flex-none mr-1" src="@/assets/images/elements/hod.png">  -->

    <div class="flex-none ml-1 title font-medium text-2xl">{{ Titlepage }}</div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters({
      activePage: "general/activePage"
    }),
    Titlepage() {
        console.log("activePage:",this.activePage);
        return this.title = this.activePage.name
    }
  },
  data() {
    return {
      title: null
    };
  }
};
</script>

<style lang="scss" scoped>
.title {
  font-family: 'Montserrat', 'Athiti', sans-serif;
  font-size: 18px;
  color: rgb(255, 255, 255);
}
hr.line-blue {
  border-top: 3px solid #7595cc;
}
.square-blue {
  width: 12px;
  height: 12px;
  transform: rotate(45deg);
  background-color: #7595cc;
}
.Header{
  background: #6A7E91
}
</style>
