<template>
  <div class="flex flex-col items-center text-center">
    <div class="Profile-pic flex justify-center">
      <!-- <img v-if="userinfo" :src="userinfo.img_url" class="pic-userstyle" /> -->
      <img
        class="pic-style"
        src="@/assets/images/logo/Central.png"
      />
      <!-- <img
        v-else
        class="object-cover h-full w-full pic-style"
        
        src="@/assets/images/elements/apple-touch-icon.png"
      /> -->
    </div>
    <div class="AdminName text-primary mt-2">
      {{ userinfo ? userinfo : "GENERAL ADMIN" }}
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {};
  },

  computed: {
    ...mapGetters({
      userinfo: "general/userinfo"
    })
  }
};
</script>

<style lang="scss" scoped>
.Profile {
  height: 150px;
  width: 150px;
}
.Profile-pic {
  height: 150px;
  width: 150px;
  border-radius: 50%;
  border: 2px solid #FFFFFF;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 5px 5px;
  overflow: hidden;
  object-fit: cover;
}
.pic-style {
  display: inline;
  margin: auto auto;
}

.pic-userstyle {
  height: 100%;
  width: 100%;
  display: inline;
  margin: 0 auto;
  object-fit: cover;
}
.AdminName {
  font-size: 1.5rem;
}
</style>
