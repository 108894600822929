<template>
  <router-link
    :to="Url"
    class="text-lg w-full cus-button"
    :class="{ active: isActive }"
    @click.native="ActivePage(Name, Slug)"
  >
    {{ Name }}
  </router-link>
</template>

<script>
export default {
  props: {
    Name: String,
    Url: String,
    Slug: String,
    isActive: Boolean
  },

  methods: {
    async ActivePage(name, slug) {
      this.$store.dispatch("general/push_activePage", {
        name: name,
        slug: slug
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.cus-button {
  display: flex;
  justify-content: start;
  padding: 15px 20px;
  text-decoration: none;

  &:hover {
    transition: 100ms;
    padding-left: 30px;
  }
}

.active {
  background-color: #6A7E91;
  color: white;
  animation-name: toActive;
  animation-duration: 500ms;
}

@keyframes toActive {
  0% {
    background-color: #f0f3f4;
    color: #4d4d4d;
  }
  100% {
    background-color: #6A7E91;
    color: white;
  }
}
</style>
