const sidebar_item = [
  {
    url: '/catalog',
    name: 'Catalog management',
    slug: 'catalog',
  },
  {
    url: '/',
    name: 'Campaign management',
    slug: 'campaign',
  },
];


export default sidebar_item;

// {
//   url: "/promotion",
//   name: "จัดการโปรโมชั่น",
//   slug: "promotion"
// },
// {
//   url: "/setting",
//   name: "ตั้งค่า",
//   slug: "setting"
// }
